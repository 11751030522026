












































































import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import SearchResultSectionComponent
  from '@/components/search-result/SearchResultSectionComponent.vue';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

/* eslint-disable no-underscore-dangle,max-len */
@Component({
  components: {
    IllustrationComponent,
    SearchResultSectionComponent,
    PillWidget,
    InputSearchComponent,
  },
})
export default class SearchResultComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'SearchResultWidgetStore';

  private IllustrationType = IllustrationType;

  private search = '';

  private isLoading = true;

  private selectedPills: string[] = ['all'];

  private get pills(): Array<{ title: string; key: string }> {
    if (this.widget && this.widget.subWidgets) {
      return [
        {
          title: 'actions.search-result.all',
          key: 'all',
        },
        ...this.widget.subWidgets.map((sub, index) => {
          const {
            title, type,
          } = JSON.parse(sub.payload || '{}');
          const key = type + index;
          return {
            key,
            title,
          };
        })];
    }
    return [];
  }

  private get totalCount(): number {
    return this.$store.getters[`${this.widgetStorePath}/totalCount`];
  }

  private get searchResults(): Record<string, {
    list: Data[];
    count: number;
    type: string;
    entityTitle: string;
    entityRoute: string;
    btnTitle: string;
    btnRoute: string;
  }> {
    if (this.widget.subWidgets && this.widget.subWidgets.length > 0) {
      return this.$store.getters[`${this.widgetStorePath}/results`];
    }
    return {};
  }

  destroyed(): void {
    window.removeEventListener('popstate', this.handlePopState);
  }

  created(): void {
    window.addEventListener('popstate', this.handlePopState);
    if (!(this.$route.query && 'search' in this.$route.query && this.$route.query.search)) {
      this.onNewSearchQuery({ query: '' });
    }
    this.search = (this.$route.query.search as string) || '';
    this.setDataConfig()
      .then(() => {
        if (this.search.length >= 2) {
          this.loadSearchResultsData(this.search);
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
  }

  private loadSearchResultsData(entityFts = ''): Promise<void> {
    this.isLoading = true;
    return this.$store.dispatch(`${this.widgetStorePath}/loadSearchResultsData`, entityFts)
      .then(() => {
        this.isLoading = false;
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code || '',
          '',
          StatLoggerActions.SEARCH,
          this.search,
          -1,
          '',
          StatLoggerCategories.GLOBAL,
          this.$i18n.locale,
          this.totalCount,
        );
        return Promise.resolve();
      }).catch(() => {
        this.isLoading = false;
      });
  }

  private handlePopState(): void {
    if (!(this.$route.query && 'search' in this.$route.query && this.$route.query.search)) {
      this.onNewSearchQuery({ query: '' });
    }
    this.search = (this.$route.query.search as string) || '';
  }

  private onNewSearchQuery(payload: { query: string }): void {
    if (this.$route.query.search !== payload.query) {
      window.history.pushState(
        { search: payload.query },
        '',
        this.$router.resolve({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            ...{
              search: payload.query,
            },
          },
        }).href,
      );
    }
  }

  private onSearchInput(payload: { query: string }): void {
    this.search = payload.query;
    this.onNewSearchQuery(payload);
    if (this.search.length >= 2) {
      this.loadSearchResultsData(this.search);
    }
  }

  private toggleSelectedPills(key: string): void {
    if (key === 'all') {
      this.selectedPills = ['all'];
    } else {
      this.selectedPills = this.selectedPills.filter((p) => p !== 'all');
      if (this.selectedPills.includes(key)) {
        this.selectedPills = this.selectedPills.filter((p) => p !== key);
      } else {
        this.selectedPills.push(key);
        const pill = this.pills.find((p) => p.key === key);
        if (pill) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.FILTER,
            `${this.$t(pill.title)}`,
            -1,
            '',
            StatLoggerCategories.GLOBAL,
            this.$i18n.locale,
          );
        }
      }
      if (this.selectedPills.length === 0) {
        this.selectedPills = ['all'];
      }
    }
  }
}
